.react-datepicker {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.189);
    border: none !important;
    font-size: 18px !important; /* Make the entire calendar's font bigger */
  }
  
  .react-datepicker__month-container {
    width: 100% !important;
    height: 100% !important;
  }
  
  .react-datepicker__triangle {
    display: none;
  }
  
  /* Make the navigation buttons larger */
  .react-datepicker-popper .react-datepicker__navigation {
    padding-top: 12px;
    color: #000;
    font-size: 24px; /* Increase arrow size */
    width: 40px; /* Increase arrow width */
    height: 40px; /* Increase arrow height */
  }
  
  /* Header styles */
  .react-datepicker__header {
    border-bottom: solid 5px var(--light) !important;
    background: white !important;
    font-size: 24px !important; /* Increase font size of the month and year */
  }
  
  .react-datepicker__current-month {
    color: var(--dark) !important;
    font-size: 20px !important; /* Make the current month name bigger */
  }
  
  /* Fix the spacing for the days of the week */
  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between; /* Distribute the days of the week evenly */
    margin-bottom: 10px; /* Add some space between day names and calendar grid */
  }
  
  .react-datepicker__day-name {
    font-size: 18px !important; /* Make the day names larger */
    width: 40px; /* Ensure width of each day name is consistent */
    text-align: center;
    padding: 5px 0;
  }
  
  /* Make each day bigger */
  .react-datepicker__day {
    width: 40px !important; /* Increase day width */
    height: 40px !important; /* Increase day height */
    font-size: 18px !important; /* Increase day font size */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Customize keyboard-selected day */
  .react-datepicker__day.react-datepicker__day--keyboard-selected {
    border: none;
    border-radius: 7px;
    background-color: var(--dark);
    color: var(--white);
  }
  
  .react-datepicker__day.react-datepicker__day--keyboard-selected:hover {
    border: none;
    border-radius: 7px;
    background-color: var(--dark);
    color: var(--white);
  }
  
  
  /* Customize selected day */
  .react-datepicker__day.react-datepicker__day--selected {
    border: none;
    border-radius: 7px;
    background-color: black;
    color: white;
    width: 40px;
    height: 40px;
  }
  
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    border: none;
    border-radius: 7px !important;
    background-color: black !important;
    color: white !important;
  }

.react-datepicker__month {
  display: flex;
  flex-direction: column;
}

.react-datepicker__week {
  display: flex;
  justify-content: space-between;
}

.react-datepicker__day {
  flex: 1;
  text-align: center;
}
